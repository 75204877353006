import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const MiddleBottomWrapper = styled('div')`
	flex-wrap: wrap;
	padding: 0;
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	justify-content: space-between;
	padding: 0 8px 20px;
	flex-wrap: wrap;
	column-gap: 40px;

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 16px;
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		flex-grow: 1;
		justify-content: flex-start;
		padding: 0 0 21px;
		font-size: var(--size-biggest);
		&::after {
			display: inline-block;
			content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmb250LXNpemU9IjEycHgiIGNsYXNzPSJNdWlTdmdJY29uLXJvb3QgTXVpU3ZnSWNvbi1mb250U2l6ZVNtYWxsIGNzcy0xYXFkNDF1LU11aVN2Z0ljb24tcm9vdCIgZm9jdXNhYmxlPSJmYWxzZSIgYXJpYS1oaWRkZW49InRydWUiPjxwYXRoIGQ9Ik03LjkyIDE5LjIzMkwxNi4wOCAxMiA3LjkyIDQuNzY4IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNTM2Ij48L3BhdGg+PC9zdmc+);
			height: 20px;
			width: 20px;
			margin-left: 5px;
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			font-size: var(--size-base);
			flex-grow: 0;
			margin-right: 60px;
			width: auto;
			border-bottom: none;
			border-right: 1px solid ${({ theme }) => theme.palette.footer.border};
			padding: 19px 16px 19px 0;
			justify-content: center;
			&::after {
				display: none;
			}
		}
	}
`

const Copyright = styled('div')`
	.MuiTypography-root {
		line-height: 20px;
	}
	> .MuiTypography-root {
		padding: 19px 0;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		.MuiTypography-root {
			line-height: 1;
		}
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper, Copyright }
