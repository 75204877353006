import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		padding: 28px 0 0;
		flex-wrap: wrap;
	}
	& .BHFooterMenus {
		order: 3;
		padding: 0;

		${({ theme }) => theme.breakpoints.up('md')} {
			order: 2;
			border-top: none;
			padding-left: 144px;
			margin-left: auto;
			margin-right: 0;
			max-width: 60%;
			gap: 20px;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			border: none;
			margin-bottom: 20px;
			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};

				.MuiTypography-root {
					line-height: 40px;
					font-size: ${({ theme }) => theme.typography.pxToRem(32)};
					${({ theme }) => theme.breakpoints.up('md')} {
						font-size: ${({ theme }) => theme.typography.pxToRem(18)};
					}
				}
			}

			& .MuiAccordionSummary-root {
				margin-bottom: ${({ theme }) => theme.spacing(1)};
				padding: 8px;
				${({ theme }) => theme.breakpoints.up('md')} {
					padding: 0;
				}
				.MuiSvgIcon-root {
					color: var(--color-primary);
					font-size: ${({ theme }) => theme.typography.pxToRem(16)};
				}
			}

			& .MuiAccordionDetails-root {
				padding: 0px 8px;
				.MuiLink-root {
					padding: 10px 0;
					font-size: var(--size-biggest);
					${({ theme }) => theme.breakpoints.up('md')} {
						font-size: var(--size-base);
						margin: 0;
					}
				}

				${({ theme }) => theme.breakpoints.up('md')} {
					padding: unset;
				}
			}
		}
	}
	& .BHNewsletter {
		padding: 72px 8px 24px;
		${({ theme }) => theme.breakpoints.up('md')} {
			padding: 0;
		}
	}
	& .BHFooterSocialLinks {
		order: 2;
		width: 100%;
		flex-grow: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: ${({ theme }) => theme.spacing(2)};
		padding-left: 3px;
		padding-bottom: 32px;
		& .BHSocialWrapper {
			& a {
				margin: 0;
				padding: 5px;
				color: var(--color-primary);
			}
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			padding-top: 50px;
			order: 3;
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 100%;
	order: 1;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(0, 2)};
		max-width: 432px;
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
